import { apolloClient } from '@/apollo'

import GET_BILLING_STATE from '@/gql/query/billing/getBillingState.gql'
import GET_BILLING_CONFIG from '@/gql/query/billing/getBillingConfig.gql'
import GET_INVOICES from '@/gql/query/billing/getInvoices.gql'
import GET_PAYMENT_METHODS from '@/gql/query/billing/getPaymentMethods.gql'

export default {
  namespaced: true,
  state: {
    subscription: null,

    isInvoicesFetched: false,
    invoices: [],

    billingConfig: null,

    paymentMethods: null,
  },
  getters: {
    subscription(state) {
      return state.subscription
    },

    isInvoicesFetched(state) {
      return state.isInvoicesFetched
    },
    invoices(state) {
      return state.invoices
    },

    billingConfig(state) {
      return state.billingConfig
    },

    paymentMethods(state) {
      return state.paymentMethods
    },

    isSubscriptionInactive(state) {
      return state.subscription && state.subscription.status === 'Inactive'
    },
    isSubscriptionActive(state) {
      return state.subscription && state.subscription.status === 'Active'
    },
    isSubscriptionCanceled(state) {
      return state.subscription && state.subscription.status === 'Canceled'
    },
    isLatestInvoicePaid(state) {
      return state.subscription
        && state.invoices.length
        && state.invoices.find(i => i.id === state.subscription.latestInvoiceId)
        && state.invoices.find(i => i.id === state.subscription.latestInvoiceId).status === 'Paid'
    },
    isLatestInvoiceUnpaid(state) {
      return state.subscription
        && state.invoices.length
        && state.invoices.find(i => i.id === state.subscription.latestInvoiceId)
        && state.invoices.find(i => i.id === state.subscription.latestInvoiceId).status === 'Unpaid'
    },
    isSubscriptionInactiveOrBasic(state, getters) {
      return getters.isSubscriptionInactive
        || (
          state.subscription
          && (getters.isSubscriptionActive || getters.isSubscriptionCanceled)
          && state.subscription.productDetails.name === 'Basic'
        )
    },
    isSubscriptionPremium(state) {
      return state.subscription
        && state.subscription.productDetails
        && state.subscription.productDetails.name === 'Premium'
    },
  },
  mutations: {
    setSubscription(state, data) {
      // eslint-disable-next-line no-undef
      state.subscription = structuredClone(data)
    },

    setIsInvoicesFetched(state, data) {
      state.isInvoicesFetched = data
    },
    setInvoices(state, data) {
      // eslint-disable-next-line no-undef
      state.invoices = structuredClone(data)
    },

    setBillingConfig(state, data) {
      // eslint-disable-next-line no-undef
      state.billingConfig = structuredClone(data)
    },

    setPaymentMethods(state, data) {
      // eslint-disable-next-line no-undef
      state.paymentMethods = structuredClone(data)
    },
  },
  actions: {
    async getSubscription({ rootGetters, commit }) {
      const response = await apolloClient.query({
        query: GET_BILLING_STATE,
        variables: {
          restaurantId: rootGetters['restaurant/restaurant'].id,
        },
      })

      commit('setSubscription', response.data.getBillingState)
    },
    async getInvoices({ rootGetters, commit }) {
      const response = await apolloClient.query({
        query: GET_INVOICES,
        variables: {
          organizationId: rootGetters['restaurant/restaurant'].organization.id,
          restaurantId: rootGetters['restaurant/restaurant'].id,
          limit: 99,
          startingAfter: undefined, // undefined or invoice.id
        },
      })

      commit('setInvoices', response.data.getInvoices)
      commit('setIsInvoicesFetched', true)
    },
    async getBillingConfig({ rootGetters, commit }) {
      const response = await apolloClient.query({
        query: GET_BILLING_CONFIG,
        variables: {
          organizationId: rootGetters['restaurant/restaurant'].organization.id,
        },
      })

      commit('setBillingConfig', response.data.getBillingConfig)
    },
    async getPaymentMethods({ rootGetters, commit }) {
      const response = await apolloClient.query({
        query: GET_PAYMENT_METHODS,
        variables: {
          organizationId: rootGetters['restaurant/restaurant'].organization.id,
        },
      })

      commit('setPaymentMethods', response.data.getPaymentMethods)
    },

    clearBillingData({ commit }) {
      commit('setSubscription', null)

      commit('setIsInvoicesFetched', false)
      commit('setInvoices', [])

      commit('setBillingConfig', null)

      commit('setPaymentMethods', null)
    },
  },
}
